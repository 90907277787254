/* xxl, large: 1350px */
$breakpoint-xxl: 1350px;
/* xl, large: 1200px */
$breakpoint-xl: 1200px;
/* lg, large: 992px */
$breakpoint-lg: 992px;
/* md, large: 768px */
$breakpoint-md: 768px;
/* sm, large: 575px */
$breakpoint-sm: 575px;

@media screen and (max-width: $breakpoint-xxl) {
  section {
    max-width: 1100px;
  }
}

@media screen and (max-width: $breakpoint-xl) {
  .container {
    max-width: 1100px;
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .ant-layout-header .head ul {
    display: none;
  }

  .exchange {
    .ant-form {
      gap: 0;

      .col-item {
        width: 100%;
      }
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .rules-card {
    .ant-tabs-nav-list {
      justify-content: center;
    }
  }

  .footer .wrapper p {
    flex: 0 0 29.33%;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .ant-layout-header {
    padding: 5px 0;
    align-items: flex-start;

    .head {
      .logo img {
        height: 36px;
      }

      .auth {
        display: none;
      }
    }
  }

  .exchange {
    padding-top: 120px;
  }

  .footer .wrapper {
    justify-content: center;
    flex-wrap: wrap;

    p {
      flex: 0 0 100%;
      margin-bottom: 10px;
      text-align: center;

      &:last-child {
        order: -1;
        text-align: center;
      }
    }
  }

  .exchange {
    .ant-form {
      .col-item {
        .box {
          .input-assets {
            .top-bottom {
              margin-bottom: 10px;
              order: -1;
            }

            .currency {
              top: 45px;
            }
          }
        }
      }
    }

    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: column;
    }
  }

  .order {
    padding: 110px 0 50px;
    .box {
      flex-direction: column;
      margin: 0;
      .side {
        margin: 0 0 20px;
      }

      .main {
        margin: 0;
      }
    }
  }

  .rules-card {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        border-radius: 24px !important;
        font-size: 16px;
        line-height: 26px;
        padding: 10px 24px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
}

@media screen and (min-width: $breakpoint-xl) {
  .container {
    max-width: 1100px;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .order .box .side {
    max-width: 452px;
  }
}
