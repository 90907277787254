/* -----------------------------------
		    1. Body and Core Css
----------------------------------- */

* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  outline: none !important;
  list-style: none !important;
  text-decoration: none !important;

  &:after,
  &:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font: {
    size: 16px;
    weight: 400;
    family: "Gilroy";
  }
  line-height: 1.5;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: #01090d;
  background-image: url(../../img/bg-min.png);
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  max-width: 1100px;
  margin: auto;
  padding: 50px 2rem;
}

/* 1.1 Typography */
h1 {
  color: #fff;
  font-size: 36px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 0;
}

h3 {
  font-size: 1.75rem;
  margin-bottom: 0;
}

h4 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

h5 {
  font-size: 1.25rem;
  margin-bottom: 0;
}

h6 {
  font-size: 1rem;
  margin-bottom: 0;
}

a {
  color: #06d8aa;
  outline: none;
  text-decoration: none;

  &:hover {
    color: #06d8aa;
    outline: none;
    text-decoration: none;
  }
}

p {
  font-size: 16px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 1rem;
}

img {
  max-width: 100%;
}

dl,
ol,
ul {
  padding: 0;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.btn-primary {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  height: auto;
  color: #000;
  font-family: "Gilroy", sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 28px;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 13px 29px;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: #06d8aa;
  border: 1px solid #06d8aa;
  border-radius: 8px;
  z-index: 1;

  &:hover {
    color: #000 !important;
    background: #134a46 !important;
    border-color: #134a46 !important;
    transition: all 0.35s;
  }

  &:focus {
    background: #06d8aa;
    border: 1px solid #06d8aa;
    box-shadow: none;
  }

  &.ant-btn-disabled {
    color: #fff;
    background: #06d8aa !important;
    border: 1px solid #06d8aa !important;
    cursor: default;
  }
}

.ant-btn-primary:disabled {
  color: #000 !important;
  background: #06d8aa !important;
  border: 1px solid #06d8aa !important;
}

.btn-second {
  color: #06d8aa;
  cursor: pointer;
  display: inline-block;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
  margin: 0;
  outline: none;
  overflow: hidden;
  height: auto;
  padding: 11px 15px;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 8px;
  background: transparent;
  border: 1px solid #06d8aa;
  z-index: 1;

  &:hover {
    color: #000 !important;
    background: #134a46 !important;
    border-color: #134a46 !important;
    transition: all 0.35s;
  }
}

// Header
.ant-layout-header {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
  height: auto;
  top: 0;
  padding: 14px 0;
  background: #01090d;
  border-bottom: 1px solid #06d8aa;
  z-index: 100;

  .head {
    display: flex;
    justify-content: space-between;

    .logo {
      position: relative;
      left: -5px;

      img {
        width: 100%;
        max-height: 45px;
      }
    }

    .auth {
      display: flex;
      align-items: center;
      gap: 33px;

      a {
        color: #fff;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 800;

        &:hover {
          color: #06d8aa;
        }
      }
    }

    ul {
      display: flex;
      margin: 0;
      padding-left: 70px;

      li {
        display: flex;
        line-height: normal;
        align-items: center;
        justify-content: center;

        a {
          color: #85868a;
          font: 500 15px/15px "Inter", sans-serif;
          line-height: 1;
          margin: 0 32px 0 0;
          text-align: center;

          &:hover {
            color: #06d8aa;
          }
        }
      }
    }

    .dropdown-btn {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      height: auto;
      color: #fff;
      font: 600 14px/28px "Inter", sans-serif;
      padding-right: 21px;
      background-color: transparent;
      border: none;

      .arrow {
        position: absolute;
        display: flex;
        top: 50%;
        right: 1px;
        height: 35px;
        transform: translateY(-50%);
        align-items: center;
      }
    }
  }
}

/* Exchange */
.exchange {
  position: relative;
  padding-top: 180px;
  margin: 0 auto;

  .box {
    position: relative;
    flex: 1 1 auto;
    padding: 24px;
    margin-bottom: 16px;
    padding: 30px 24px;
    border-radius: 12px;
    background: #171a25;
    border: 1px solid #06d8aa;
    z-index: 1;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }

    p {
      margin-bottom: 0;

      span {
        color: #06d8aa;
      }

      &:first-child {
        margin-bottom: 25px;
      }
    }
  }

  .ant-form {
    display: flex;
    flex-wrap: wrap;
    gap: 19px;

    .col-item {
      width: 32%;

      .box {
        padding: 25px 24px;

        .title {
          font-family: "Gilroy";
        }

        .input-assets {
          position: relative;
          margin-bottom: 0;

          .currency {
            position: absolute;
            top: 15px;
            right: 50px;
            color: silver;
            font: 500 16px / 28px "Gilroy", sans-serif;
            z-index: 1;
          }

          .top-bottom {
            color: #fff;
            display: flex;

            height: 12px;
            justify-content: space-between;
            letter-spacing: 0.2px;
            line-height: 1;
            margin-top: 10px;

            p {
              font-size: 12px;
              font-weight: 600;

              span {
                color: #85868a;
                margin-left: 3px;
              }
            }
          }
        }

        .item-coin {
          align-items: center;
          display: flex;
          flex-wrap: nowrap;
          margin-bottom: 16px;
          padding-bottom: 16px;

          .icon {
            background: #141414;
            border: 1px solid #2b2b2b;
            border-radius: 50%;
            flex: 0 0 38px;
            height: 38px;
            margin-right: 12px;
            padding: 4px;

            img {
              border-radius: 50%;
              display: block;
              height: 100%;
              -o-object-fit: cover;
              object-fit: cover;
              width: 100%;
            }
          }

          .title {
            color: #fff;
            flex: 1 1 auto;
            font-size: 16px;
            font-weight: 700;
            line-height: 28px;
            margin-bottom: 0;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          s {
            display: flex;
            color: #85868a;
            flex: 0 1 auto;
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            margin-left: 16px;
            text-align: right;
            white-space: nowrap;

            img {
              margin-left: 12px;
            }
          }
        }

        .rate {
          font-family: "Gilroy";
          background: #06d8aa;
          border-radius: 0 0 8px 8px;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          line-height: 28px;
          margin: 0 -24px -25px;
          padding: 16px 24px;
          text-align: center;
        }

        .data-form {
          .ant-form-item {
            position: relative;
            margin-bottom: 0;

            .ant-input {
              color: #fff;
              width: 100%;
              height: 60px;
              padding: 20px;
              border-top: 0;
              border-left: 0;
              border-right: 0;
              border-radius: 0;
              letter-spacing: -0.2px;
              border: 1px solid #292c35;
              background: #01090d;
              font: 600 16px / 28px "Gilroy", sans-serif;
            }

            &:first-child {
              .ant-input {
                border-top-left-radius: 18px;
                border-top-right-radius: 18px;
              }
            }

            &:last-child {
              .ant-input {
                border-bottom-left-radius: 18px;
                border-bottom-right-radius: 18px;
              }
            }

            .icon-coin {
              position: absolute;
              top: 18px;
              right: 18px;

              img {
                width: 27px;
                height: 27px;
                min-width: 27px;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }

  .notification {
    position: fixed;
    right: -100%;
    top: 0;
    display: flex;
    align-items: center;
    color: #fff;
    line-height: 1.3;
    margin: 20px;
    max-width: 350px;
    padding: 20px;
    z-index: 1000;
    border-radius: 4px;
    background-color: #ffca28;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
    transition: all 0.3s;

    &:before {
      background: url(../../img/icon/info.svg) 50% / cover no-repeat;
      content: "";
      display: block;
      height: 30px;
      margin-right: 20px;
      min-width: 30px;
      width: 30px;
    }

    &.active {
      right: 0;
    }
  }
}

/* Order */
.order {
  padding: 195px 0 100px;

  .order-page-top {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .title-h4 {
      color: #fff !important;
      font: 700 20px / 1.2 "Inter", sans-serif;
      margin: 0 20px 10px 0;
      text-align: left;
    }

    .payment-data_date {
      align-items: center;
      background: #06d8aa;
      border-radius: 8px;
      color: #01090d;
      display: flex;
      font-size: 14px;
      padding: 12px;
      margin-bottom: 10px;

      span {
        display: inline-block;
        font-weight: 700;
        margin-left: 6px;
      }
    }
  }

  .box {
    color: #85868a;
    display: flex;
    margin: 0 -10px;

    &.rules {
      display: block;
      text-align: left;
      padding: 25px 22px;

      h3 {
        font-size: 150%;
        margin-bottom: 1em;
        line-height: 1.2;
        text-align: center;
      }
    }

    .side {
      position: relative;
      width: 100%;
      margin: 10px;
      padding: 24px;
      background: #171a25;
      border: 1px solid #06d8aa;
      border-radius: 12px;
      z-index: 1;

      .table {
        width: 100%;

        table {
          border-collapse: collapse;
          width: 100%;
        }

        td {
          border-bottom: 1px solid #535763;
          font-size: 14px;
          padding: 12px;
          &:first-child {
            padding-left: 0;
          }
        }

        tr:last-child td {
          border-bottom: none;
        }

        img {
          border-radius: 50%;
          display: block;
          flex: none;
          height: 28px;
          margin-right: 10px;
          width: 28px;
        }

        p {
          font-size: 14px;
          color: #fff;
          font-weight: 600;
          margin-bottom: 0;
        }

        .-row {
          align-items: center;
          display: flex;

          p {
            word-break: break-all;
          }
        }
      }

      &::before {
        content: "";
        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        width: 100%;
        background: url(../../img/header-bg.png) 0 0 repeat;
        z-index: -1;
      }
    }

    .main {
      position: relative;
      width: 100%;
      margin: 10px;
      padding: 24px;
      background: #171a25;
      border: 1px solid #06d8aa;
      border-radius: 12px;
      z-index: 1;

      &::before {
        content: "";
        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        width: 100%;
        background: url(../../img/header-bg.png) 0 0 repeat;
        z-index: -1;
      }

      .title {
        h1 {
          color: #fff;
          font: 700 20px / 1.2 "Inter", sans-serif;
          letter-spacing: -0.5px;
          margin: 0 0 35px;
        }
      }

      .qr-block {
        position: absolute;
        right: 43px;
        top: 61px;

        .qr {
          cursor: pointer;
          align-items: center;
          display: inline-flex;
          justify-content: center;
          color: #fff;
          height: 47px;
          font: 800 14px / 14px "Inter", sans-serif;
          padding: 11px 15px;
          background: none;
          border-radius: 8px;
          box-shadow: none;
          border-color: transparent;

          &:hover {
            border-color: #134a46;
          }
        }

        .merchant-qrcode {
          background: #fff;
          border-radius: 12px;
          display: none;
          height: 240px;
          margin: 0;
          padding: 20px;
          position: absolute;
          right: 3px;
          top: 56px;
          width: 240px;
          z-index: 3;

          &::before {
            background: #fff;
            content: "";
            height: 20px;
            position: absolute;
            right: 15px;
            top: -10px;
            transform: rotate(45deg);
            width: 20px;
          }

          .ant-qrcode {
            width: 200px !important;
            height: 200px !important;
            padding: 0;
            border: none;
            border-radius: 0;
          }

          &.active {
            display: block;
          }
        }
      }

      .order-card {
        height: auto;
        margin-top: 1.5rem;
        margin-bottom: 3rem;

        .input-group {
          margin: 16px 0;

          label {
            display: block;
            line-height: 16.41px;
            font-size: 16px;
            font-weight: 700;
            margin-right: 12px;
            margin-bottom: 9px;
            padding-right: 12px;
            white-space: nowrap;
          }

          input {
            width: auto;
            min-width: 245px;
            background: #01090d;
            border: 1px solid #292c35;
            border-radius: 8px;
            color: silver;
            height: auto;
            padding: 13px 16px;
            word-break: break-all;
          }

          .w-100 {
            width: 100% !important;
          }
        }
      }

      .warning-message {
        background-color: #fde6d4;
        border-radius: 4px;
        padding: 15px 20px;
        margin: 10px 0;

        .title {
          font-size: 16px;
          color: #bb8962;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        .text {
          p {
            text-align: left;
            color: #b19179;
            font-size: 15px;
          }
        }
      }

      .order-btns {
        display: flex;
        justify-content: space-between;

        .btn-second {
          padding: 0px 30px;

          &.grey {
            svg {
              transform: scale(-1);
              margin-right: 0.5rem !important;
            }
          }

          &.red {
            svg {
              margin-right: 0.5rem !important;
            }
          }
        }
      }
    }
  }
}

/* Rules */
.rules {
  position: relative;
  padding: 165px 0 50px;

  h1 {
    font: 700 64px / 1.2 "Inter", sans-serif;
    letter-spacing: -0.5px;
    margin: 0 0 24px;
  }

  h5 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font: 600 16px / 1.2 "Inter", sans-serif;
    margin: 0;
    padding: 5px 0 8px;
    line-height: 19px;
  }

  ul {
    list-style: none;
    padding: 2px 0 2px 16px;

    li {
      display: flex;
      color: #fff;
      font-size: 16px;
      line-height: 24px;
      font-family: "Manrope", sans-serif !important;
      margin: 0 !important;
      padding: 5px 0 15px;

      &:before {
        color: #06d8aa;
        content: "•";
        font-size: 24px;
        margin-right: 10px;
      }
    }
  }

  u {
    display: block;
    margin-bottom: 0;
    text-decoration: underline !important;
  }

  h2 {
    color: #fff;
    line-height: 1.2;
    margin: 0;
    padding: 32px 0 8px;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.38;
    margin-top: 18pt;
    margin-bottom: 4pt;
  }

  .returns {
    p {
      margin-bottom: 7px;
    }
  }
}

/* Footer */
.footer {
  background-color: transparent;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;

    p {
      color: #85868a;
      flex: 0 0 33.33%;
      font: 12px "Gilroy", sans-serif;

      &:last-child {
        text-align: right;
      }
    }

    .info {
      display: flex;
      align-items: center;
      gap: 16px;

      a {
        img {
          border-radius: 8px;
        }
      }

      .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 58px;
        width: 58px;
        padding: 11px;
        border-radius: 50%;
        background: #06d8aa;
        text-decoration: underline;
        transition: all 0.35s;

        &:hover {
          background: #134a46;
        }
      }
    }
  }
}
