// Core
.ant-col {
  font-family: "Gilroy";
  min-height: auto;
}

// Input
.ant-input {
  width: 100%;
  color: #cfd9db;
  font-size: 16px;
  font-weight: 400;
  height: 46px;
  border-radius: 3px;
  background: #2d3537;
  border: 1px solid #2d3537;
  outline: none;
  box-shadow: none;

  &::placeholder {
    color: #6c757d;
  }

  &:hover {
    box-shadow: none;
    background: #283031;
    border-color: #323a3d;
  }

  &:focus {
    border: 1px solid rgba(154, 163, 165, 0.35) !important;
    box-shadow: none !important;
  }
}

.ant-input-number {
  width: 100%;
  color: #fff;
  height: 55px;
  padding: 12px 15px;
  font: 600 16px / 28px "Gilroy", sans-serif;
  background: #01090d;
  border: 1px solid #292c35 !important;
  border-right: none !important;
  border-radius: 8px;
  outline: none;
  box-shadow: none;

  .ant-input-number-input {
    color: #fff;
    padding: 0;

    &::placeholder {
      color: #6c757d;
    }
  }

  &::placeholder {
    color: #6c757d;
  }

  &:hover {
    box-shadow: none;
    background: #01090d;
    border: 1px solid #292c35;
    border-right: none;
  }

  .ant-input-number-focused {
    border: 1px solid #292c35;
  }
}

.ant-input-number-group-wrapper {
  width: 100%;

  .ant-input-number-group {
    .ant-input-number-group-addon {
      background: #01090d;
      border: 1px solid #292c35;
      border-left: none;
      border-radius: 0 8px 8px 0;

      img {
        width: 27px;
        height: 27px;
        min-width: 27px;
        border-radius: 50%;
      }
    }
  }
}

// Selcet
.ant-select:not(.ant-select-customize-input) {
  box-shadow: none !important;

  .ant-select-selector {
    cursor: pointer;
    color: #cfd9db;
    font-size: 16px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    background: #52585a;
    line-height: 34px;
    border: 1px solid rgba(154, 163, 165, 0.35);
    border-radius: 3px;
    outline: none;
    box-shadow: none;

    .ant-select-selection-item {
      text-align: left;
      display: flex;
      align-items: center;
    }

    .ant-select-selection-search-input {
      position: relative;
      cursor: pointer !important;
      left: -10px;
      width: calc(100% - 20px);
      height: 32px !important;
      color: #818d8f !important;
      padding: 3px 10px;
      margin: 6px 0;
      border: none !important;
      outline: none !important;
      border-radius: 4px;
      background-color: #2d3537;
      opacity: 0;
      z-index: 1;
    }
  }

  &.ant-select-focused {
    box-shadow: none !important;

    .ant-select-selector {
      border: 1px solid rgba(154, 163, 165, 0.35) !important;

      .ant-select-selection-search-input {
        opacity: 1 !important;
      }
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: none !important;

    .ant-select-selector {
      background: #4d5355;
      border-color: rgba(173, 180, 182, 0.35) !important;
    }
  }

  .ant-select-arrow {
    inset-inline-end: 1px;
    margin-top: -4px;
  }
}

.ant-select-single {
  height: 46px;
  min-width: 212.5px;

  &.ant-select-open {
    .ant-select-selection-item {
      img {
        margin-right: 7px;
        height: 20px;
        width: 20px;
      }
    }
  }
  &.ant-select-show-arrow {
    .ant-select-selection-item {
      img {
        margin-right: 7px;
        height: 20px;
        width: 20px;
      }
    }
  }
}

// Select Dropdown
.ant-select-dropdown {
  color: #cfd9db;
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  background: #52585a;
  line-height: 34px;
  padding: 0;
  border: 1px solid rgba(154, 163, 165, 0.35);
  border-radius: 0px 0px 3px 3px;
  outline: none;
  box-shadow: none;
  margin-top: -5px;

  .ant-select-item {
    min-height: 46px;
    border-radius: 0;
    margin-right: 12px;

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      font-family: "Open Sans", sans-serif;

      img {
        margin-right: 7px;
        height: 20px;
        width: 20px;
      }
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff !important;
    font-weight: 600;
    background-color: #2d3537;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #323a3c;
  }

  &::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 100%;
    top: 0;
    right: 0;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #fff0;
  }
}

// Checkbox
.ant-checkbox-wrapper {
  width: 100%;
  margin-top: 15px;

  &:hover {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #2d3537 !important;
        border-color: transparent !important;
      }
    }
  }
}

.ant-checkbox {
  top: 2px;
  align-self: flex-start;

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border: none;
    overflow: hidden;
    border-radius: 3px;
    background-color: #000;
    border: 1px solid #2b2b2b !important;

    &:after {
      top: 0 !important;
      width: 20px;
      height: 20px;
      border-top: 0;
      border-inline-start: 0;
      transform: none !important;
      background: #06d8aa;
      inset-inline-start: 0;
      opacity: 0;
    }
  }

  + span {
    color: #fff;
    cursor: pointer;
    display: block;
    font: 500 14px / 20px "Gilroy", sans-serif;
    position: relative;
    user-select: none;

    a {
      font-weight: 600;
    }
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    &:after {
      top: 0;
      opacity: 1;
    }
  }
}

// Modal
.ant-modal {
  .ant-modal-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    border: 0;
    text-align: left;
    font-family: "Open Sans", sans-serif;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-qrcode {
    border: none;
    margin: 40px auto 0;
  }

  &.modal-delete,
  &.modal-pay {
    .ant-modal-title {
      text-align: center;
    }

    .d-flex {
      gap: 5px;
      margin-top: 30px;
      justify-content: center;
    }
  }

  &.modal-pay {
    p {
      color: #000;
      font-size: 17px;
      margin-top: 30px;
    }
  }
}

// DropDown Lang
.ant-dropdown-placement-bottomLeft {
  .ant-dropdown-menu {
    background: #01090d;
    border: 1px solid #000;
    border-radius: 10px;
    box-shadow: 0 64px 194px rgba(6, 216, 170, 0.5);
    left: 0;
    padding: 0;
    overflow: hidden;
    z-index: 10;

    .ant-dropdown-menu-item {
      color: #fff;
      display: block;
      font: 500 16px / 24px "Gilroy", sans-serif;
      padding: 14px 48px 14px 16px;
      transition: none;

      img {
        flex: 0 0 20px;
        margin-right: 10px;
        max-width: 20px;
      }

      &:hover {
        background: #06d8aa;
        color: #000;
      }
    }
  }
}

// Tabs
.ant-tabs {
  .ant-tabs-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 31px;
    color: #85868a;
    font-size: 14px;
    font-weight: 500;
    font-family: "Gilroy";
    line-height: 18px;
    padding: 0 15px;
    text-transform: uppercase;
    background: #000;
    border-radius: 8px;
    border: 1px solid #292c35;

    + .ant-tabs-tab {
      margin: 0 0 0 8px;
    }

    &.ant-tabs-tab-active {
      background: #06d8aa;
      border-color: #06d8aa;
      font-weight: 700;

      .ant-tabs-tab-btn {
        color: #01090d;
      }
    }

    &:hover {
      color: #85868a;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
}

.ant-tabs-top {
  > .ant-tabs-nav {
    &::before {
      display: none;
    }
  }
}

.rules-card {
  .ant-tabs-nav-list {
    flex-wrap: wrap;
    justify-content: flex-start;
    left: -0.5rem;
    margin-bottom: 20px;

    .ant-tabs-tab {
      cursor: pointer;
      position: relative;
      display: inline-block;
      width: auto;
      height: auto;
      color: #000;
      font-family: "Gilroy", sans-serif;
      font-size: 18px;
      font-weight: 800;
      line-height: 28px;
      margin: 0.5rem !important;
      outline: none;
      padding: 13px 29px;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      background: #06d8aa;
      border: 1px solid #06d8aa !important;
      border-radius: 8px !important;
      text-transform: none !important;
      z-index: 1;
    }
  }
}

// Radio
.ant-radio-group {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 353px;
  margin: 0 -24px -24px;
  overflow-y: auto;
  padding: 0 24px 24px;

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  &::-webkit-scrollbar {
    height: 2px;
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #06d8aa;
  }
}

.ant-radio-button-wrapper {
  height: auto;
  color: #fff;
  padding: 6px 16px 6px 8px;
  margin-bottom: 10px;
  background: #01090d;
  border: none;
  border-left: 4px solid #292c35;
  border-radius: 0 8px 8px 0;
  transition: all 0.4s;

  &::before {
    display: none !important;
  }

  &:first-child {
    border: none;
    border-left: 4px solid #292c35;
    border-radius: 0 8px 8px 0;
  }

  s {
    color: #fff;
    flex: 1 1 auto;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .logo {
    background: #171a25;
    border: 1px solid #292c35;
    border-radius: 50%;
    flex: 0 0 40px;
    margin-right: 12px;
    max-width: 40px;
    padding: 5px;
    transition: all 0.4s;

    img {
      border-radius: 50%;
      display: block;
      height: 28px;
      min-width: 28px;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
    }
  }

  span {
    position: relative;
    display: flex;
    align-items: center;
  }

  p {
    color: #85868a;
    flex: 0 1 auto;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    margin-left: 16px;
    text-align: right;
    white-space: nowrap;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #134a46 !important;
  border-color: #06d8aa !important;

  .logo {
    background-color: #000;
    border: 2px solid #06d8aa;
    padding: 4px;
  }

  &::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: 14px;
    right: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(../../img/icon/check.svg);
  }

  p {
    display: none;
  }

  &:hover {
    border-color: #06d8aa;
  }
}
